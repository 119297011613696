// import GETUSERINFO from './action-type';
import * as actionType from './action-type';

const DefaultUserState = {
  userid: '',
  username: '',
  rbacGroup: null,
  isadmin: false,
  userRbac: {
    status: -1,
    data: null,
    msg: '',
  },
};

const userInfo = (state = DefaultUserState, action = {}) => {
  switch (action.type) {
    case actionType.GETUSERINFO:
      return { ...state, userid: action.userid, username: action.username, rbacGroup: action.rbacGroup, isadmin: action.isadmin };
    case actionType.UPDATEUSERRBAC:
      return { ...state, userRbac: { status: action.status, data: action.data, msg: action.msg } };
    default:
      return state;
  }
};

export default userInfo;
