import GETPRODUCTLIST from './action-type';

// 列表
const DefaultProductList = {
  status: -2,
  data: {},
  msg: '',
};
const DefaultStore = {
  productList: { ...DefaultProductList },
};

const product = (state = DefaultStore, action = {}) => {
  switch (action.type) {
    case GETPRODUCTLIST:
      return { ...state, productList: { data: action.data, status: action.status, msg: action.msg } };
    default:
      return state;
  }
};

export default product;
