/**
 * Created by mzj on 2018/9/19.
 */

import { combineReducers } from 'redux';
import userInfo from './user';
import shopInfo from './shop';
import page from './page';
import config from './config';
import product from './product';
import shopStation from './station';
import global from './global';

export default combineReducers({
  userInfo,
  shopInfo,
  page,
  shopStation,
  config,
  product,
  global,
});
