import * as actype from './action-type';

// 列表
const DefaultOSSConfig = {
  status: -2,
  data: {},
  msg: '',
};

// 分类
const DefaultProductCates = {
  status: -2,
  data: [],
  msg: '',
};

// 科目
const DefaultProductSubjects = {
  status: -2,
  data: {},
  msg: '',
};

// 年份
const DefaultProductYears = {
  status: -2,
  data: [],
  msg: '',
};

const DefaultStore = {
  OSSConfig: { ...DefaultOSSConfig },
  productCates: { ...DefaultProductCates },
  productSubjects: { ...DefaultProductSubjects },
  productYears: { ...DefaultProductYears },
};

const config = (state = DefaultStore, action = {}) => {
  switch (action.type) {
    case actype.GETOSSCONFIG:
      return { ...state, OSSConfig: { data: action.data, status: action.status, msg: action.msg } };
    case actype.GETPRODUCTCATES:
      return { ...state, productCates: { data: action.data, status: action.status, msg: action.msg } };
    case actype.GETPRODUCTSUBJECTS:
      return { ...state, productSubjects: { data: action.data, status: action.status, msg: action.msg } };
    case actype.GETPRODUCTYEARS:
      return { ...state, productYears: { data: action.data, status: action.status, msg: action.msg } };
    default:
      return state;
  }
};

export default config;
