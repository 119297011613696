import * as actionType from './action-type';
import Fetch from '../../utils/fetch-util';
import { Interfaces } from '../../service/serverConfig';

function updateAllShopsList(status = -2, data = null, msg = '') {
  return {
    type: actionType.UPDATEALLSHOPSLIST,
    status,
    data,
    msg,
  };
}

function getAllShopsList() {
  return function (dispatch) {
    return Fetch.get(Interfaces.getShopsList, {}, (result) => {
      return dispatch(updateAllShopsList(0, result, ''));
    }, (msg, code) => {
      return dispatch(updateAllShopsList(code, null, msg));
    });
  };
}

export {
  updateAllShopsList,
  getAllShopsList,
};
