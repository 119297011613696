import axios from 'axios';
import objectAssign from 'object-assign';
import Qs from 'qs';
// import updateGlobalLoad from '../redux/global/action';
// import store from '../redux/store';

/**
 * 通过一个规格化的 Error 来获取详细错误信息，规格化的内容如下
 * JSON.stringfy({
 *   code:            除了 HTTP 状态码为 3xx 和 200 返回 0 以外，其他均保留 HTTP 状态码
 *   data:
 *   msg:
 * })
 */
const formateError = (code, data, msg) => {
  return new Error(JSON.stringify({
    code,
    data,
    msg,
  }));
};

/**
 * 解构规格化后的 Error 对象
 * @param err         Error
 * @returns {any}
 */
export const decodeError = (err) => {
  try {
    return JSON.parse(err.msg);
  } catch {
    return {
      code: 10001,
      data: null,
      msg: err.msg,
    };
  }
};

/**
 * 统一规格化
 *    组件中通过 resolve 或者 callback 回调的数据结构
 * @param code
 * @param data
 * @param msg
 * @returns {{code: *, data: *, msg: *}}
 */
const formateRes = (code, data, msg) => {
  return {
    code,
    data,
    msg,
  };
};

/**
 * 校验
 * @param response
 * @returns {Promise<any>}
 */
function checkHTTPStatus(response) {
  const status = response.status;

  if (status < 200) {
    throw formateError(status, response.data, 'Continue ...');
  }

  if ((status > 200 && status < 400) || status === 200) {
    return formateRes(0, response.data, 'success');
  }

  if ((status > 400 && status < 500) || status === 400) {
    switch (status) {
      case 400:
        throw formateError(status, response.data, 'Bad Request');
      case 401:
        throw formateError(status, response.data, 'Unauthorized');
      case 404:
        throw formateError(status, response.data, 'Not Found');
      case 408:
        throw formateError(status, response.data, 'Request Time-out');
      default:
        throw formateError(status, response.data, 'Permission Refused');
    }
  } else {
    switch (status) {
      case 500:
        throw formateError(status, response.data, 'Internal Server Error');
      case 501:
        throw formateError(status, response.data, 'Not Implemented');
      case 502:
        throw formateError(status, response.data, 'Bad Gateway');
      case 503:
        throw formateError(status, response.data, 'Service Unavailable');
      case 504:
        throw formateError(status, response.data, 'Gateway Time-out');
      case 505:
        throw formateError(status, response.data, 'HTTP Version not supported');
      default:
        throw formateError(status, response.data, 'Unkown Error');
    }
  }
}
const pending = []; // 声明一个数组用于存储每个请求的取消函数和axios标识
const removePending = (config) => {
  pending.forEach((item, index) => {
    if (item.u === `${config.method}_${config.url}_${JSON.stringify(config.params)}_${JSON.stringify(config.data)}`) {
      item.f('取消请求');
      pending.splice(index, 1);
    }
  });
  // if (!pending.length && store.getState().global.globalLoading) {
  //   store.dispatch(updateGlobalLoad(false));
  // }
};

// http请求拦截器
axios.interceptors.request.use((config) => {
  removePending(config); // 在一个axios发送前执行一下取消操作
  if (config && config.params && config.params.allowRequest) {
    delete config.params.allowRequest;
    return Promise.resolve(config);
  }
  config.cancelToken = new axios.CancelToken((c) => {
    // 这里的axios标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
    pending.push({ u: `${config.method}_${config.url}_${JSON.stringify(config.params)}_${JSON.stringify(config.data)}`, f: c });
    // if (!store.getState().global.globalLoading) {
    //   store.dispatch(updateGlobalLoad(true));
    // }
  });
  return Promise.resolve(config);
}, (error) => {
  return Promise.reject(error);
});

// http响应拦截器
axios.interceptors.response.use((data) => {
  removePending(data.config); // 在一个axios响应后再执行一下取消操作，把已经完成的请求从pending中移除
  return Promise.resolve(data);
}, (error) => {
  // 加载失败
  return Promise.reject(error);
});

class FetchUtil {
  // 主动终止所有请求，可以将前路由页面的请求全部中断，防止干扰下页数据，以及优化性能
  static removePending() {
    if (pending.length) {
      pending.forEach((item, index) => {
        item.f('取消请求');
        pending.splice(index, 1);
      });
    }
  }

  /**
   * 统一封装 catch 异常。
   *    通过返回 Promise 支持现代异步处理方案，同时通过接收回调参数兼容回调的处理方案
   * @param e             Error 对象
   * @param cb            使用回调方法这一方式，其中失败的回调方法
   */
  static _doError(e, cb) {
    const err = decodeError(e);
    if (cb) cb(err.msg, err.code, err.data);
    throw e;
  }

  /**
   * 统一封装 response 成功操作
   *    通过返回 Promise 支持现代异步处理方案，同时通过接收回调参数兼容回调的处理方案
   *
   * @param response              http response 对象，注意的是，这里的 response.data 才是真正后端返回的 Body 体
   * @param successCB             成功的回调方法
   * @param errorCB               失败的回调方法
   */
  static _doSuccess(response, successCB, errorCB) {
    const resBody = response.data;

    if (resBody.code === 0) {
      if (successCB) successCB(resBody.data, resBody.code, resBody.msg);
      return resBody;
    }

    if (errorCB) {
      errorCB(resBody.msg, resBody.code, resBody.data);
    } else {
      throw formateError(resBody.code, resBody.data, resBody.msg);
    }
  }

  static _request = (url, userConfig, successCB, errorCB) => {
    const defaultConfig = {
      headers: {
        Accept: 'application/json, */*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
    const config = {
      ...defaultConfig,
      ...userConfig,
    };
    return window.sso.ready().then((user) => {
      return axios(url, {
        ...config,
      }).then((response) => {
        return checkHTTPStatus(response);
      }).then((response) => {
        return FetchUtil._doSuccess(response, successCB, errorCB);
      })
        .catch((e) => {
          if (axios.isCancel(e)) {
            return console.log('Rquest canceled', e.msg, url); // 请求如果被取消，这里是返回取消的message
          }
          if (!e.response || !user.isNotLogin(e.response.status, e.response.headers)) {
            return FetchUtil._doError(e, errorCB);
          }
        });
    });
  };

  /**
   * 配置如 sso ref 等通用参数
   * @param params
   * @returns {{admin_sso_ref: string}}
   */
  static setCommonParams(params) {
    return {
      ...params,
      admin_sso_ref: window.location.href,
    };
  }

  /**
   * 请求方法里面，为了同时支持 promise 调用和回调调用；
   *  这里返回值是不同的，回调调用需要在封装内部捕捉 catch 异常
   *  而 promise 调用，由于需要在外部捕捉 catch 异常，内部反而不能封装
   */

  /*
  * data interaction by type of POST
  *
  * @params   url                 String; intereface address
  * @params   params              Object; request data
  * @params   successCallback     Function; success callback function which can get a param for response's data
  * @params   errorCallback       Function; error callback function, which can get a param for response's message
  * @params   option(optional)    Object; axios config
  *
  * */
  // static post(url, data = {}, successCB, errorCB, option = {}) {
  //   const params = FetchUtil.setCommonParams(data);

  //   if (successCB || errorCB) {
  //     return FetchUtil._request(url, {
  //       ...option,
  //       data: {
  //         ...params,
  //       },
  //       method: 'post',
  //     }, successCB, errorCB).catch((e) => {
  //       return FetchUtil._doError(e, errorCB);
  //     });
  //   }

  //   return FetchUtil._request(url, {
  //     ...option,
  //     data: {
  //       ...params,
  //     },
  //     method: 'post',
  //   });
  // }

  static post(url, params = {}, successCallback = () => { }, errorCallback = (msg) => { alert(msg); }, option = {}, canback = true) {
    const defaultOptions = {
      contentType: 'application/json',
      accept: 'application/json, */*',
      with_cookie: true,
    };
    const options = objectAssign({}, defaultOptions, option);
    const isFormData = options.contentType.indexOf('multipart/form-data') === 0;

    let bodyS = {};
    if (options.contentType === 'application/json') {
      bodyS = JSON.stringify(params);
      if (canback) {
        bodyS = JSON.stringify(FetchUtil.setCommonParams(params));
      }
    } else if (isFormData) {
      bodyS = new FormData();
      const paramKeys = Object.keys(params);
      for (let i = 0; i < paramKeys.length; i += 1) {
        bodyS.append(paramKeys[i], params[paramKeys[i]]);
      }
    } else {
      bodyS = Qs.stringify(params);
      if (canback) {
        bodyS = Qs.stringify(FetchUtil.setCommonParams(params));
      }
    }

    const defaultConfig = {
      headers: {
        Accept: options.accept,
        'Content-Type': options.contentType,
      },
    };
    if (options && 'withCredentials' in options) {
      defaultConfig.withCredentials = option.withCredentials;
    }

    if (successCallback || errorCallback) {
      return FetchUtil._request(url, {
        ...defaultConfig,
        data: bodyS,
        method: 'post',
      }, successCallback, errorCallback);
    }

    return FetchUtil._request(url, {
      ...defaultConfig,
      data: bodyS,
      method: 'post',
    });
  }

  /**
   * data interaction by type of PUT
   *
   * @param url
   * @param params
   * @param successCallback
   * @param errorCallback
   * @param option
   */
  static put(url, data = {}, successCB, errorCB, option = {}, canback = true) {
    let params = data;
    if (canback) {
      params = FetchUtil.setCommonParams(data);
    }
    if (successCB || errorCB) {
      return FetchUtil._request(url, {
        ...option,
        data: {
          ...params,
        },
        method: 'put',
      }, successCB, errorCB).catch((e) => {
        return FetchUtil._doError(e, errorCB);
      });
    }

    return FetchUtil._request(url, {
      ...option,
      data: {
        ...params,
      },
      method: 'put',
    });
  }

  /**
   * data interaction by type of Delete
   *
   * @param url
   * @param params
   * @param successCallback
   * @param errorCallback
   * @param option
   */
  static delete(url, data = {}, successCB, errorCB, option = {}, canback = true) {
    let params = data;
    if (canback) {
      params = FetchUtil.setCommonParams(data);
    }

    if (successCB || errorCB) {
      return FetchUtil._request(url, {
        ...option,
        data: {
          ...params,
        },
        method: 'delete',
      }, successCB, errorCB).catch((e) => {
        return FetchUtil._doError(e, errorCB);
      });
    }

    return FetchUtil._request(url, {
      ...option,
      data: {
        ...params,
      },
      method: 'delete',
    });
  }

  /**
   * data interaction by type of get
   *
   * @param url
   * @param params
   * @param successCB
   * @param errorCB
   * @param option
   */
  static get(url, data = {}, successCB, errorCB, option = {}, canback = true) {
    let params = data;
    if (canback) {
      params = FetchUtil.setCommonParams(data);
    }

    if (successCB || errorCB) {
      return FetchUtil._request(url, {
        ...option,
        params: {
          ...params,
        },
        method: 'get',
      }, successCB, errorCB).catch((e) => {
        return FetchUtil._doError(e, errorCB);
      });
    }

    return FetchUtil._request(url, {
      ...option,
      params: {
        ...params,
      },
      method: 'get',
    });
  }

  // a标签下载
  static aDownLoad(url, name, isHref) {
    let a = document.createElement('a');
    const onClick = (nowUrl) => {
      a.href = nowUrl;
      a.target = '_self';
      if (name) {
        a.download = name;
      }
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      a = null;
    };
    if (url.indexOf('.zip') > -1 || url.indexOf('.rar') > -1 || isHref) {
      onClick(url);
    } else {
      fetch(url).then(res => res.blob()).then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        onClick(blobUrl);
        window.URL.revokeObjectURL(blobUrl);
      });
    }
  }
}

export default FetchUtil;
