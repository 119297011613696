/**
 * Created by mzj on 2018/9/20.
 */

function getDomain() {
  return `${window.location.protocol}//${window.location.host}`;
}

const server = getDomain();

const ServerConfig = {
  server,
  dir: server,
  interfaces: {
    // logout_href: '/api/auth/user/cms/logout',   //旧sso退出登录地址  新sso已废弃
    getProductCates: '/api/admin/goods/GetProductCates', // 添加商品--获取分类列表
    getProductSubjects: '/api/admin/goods/GetProductSubjects', // 添加商品--获取分类下科目列表
    getProductYears: '/api/admin/goods/GetProductYears', // 添加商品--获取年份列表
    getProductList: '/api/admin/goods/GetProductList', // 添加商品--获取商品列表
    getOSSConfig: '/api/admin/getOSSConfig', // 获取阿里云oss上传配置项
    getPageList: '/api/admin/page', // 获取站点页面列表
    addPage: '/api/admin/page', // 添加页面
    copyPage: '/api/admin/copypage', // 拷贝页面及页面内容
    updatePage: '/api/admin/page/update', // 更新页面基本信息
    getPageInfo: '/api/admin/page', // 获取页面详情
    setFirstPage: '/api/admin/homePage', // 修改某个页面为站点首页
    renamePage: '/api/admin/page', // 页面重命名
    deletePage: '/api/admin/page', // 删除某个页面
    designPage: '/api/admin/page/design', // 页面装修
    userinfo: '/api/admin/getUsername',
    getShopsList: '/api/admin/getShopList', // 获取用户所有店铺'
    getShopsListAll: '/api/admin/getShopListAll', // 获取系统所有店铺'
    getStationList: '/api/admin/getStationList', // 获取站点列表
    addStation: '/api/admin/addStation', // 新建站
    getStationDetail: '/api/admin/getStationInfo', // 获取站点详细信息
    saveStation: '/api/admin/saveStation', // 编辑站
    checkStation: '/api/admin/checkStation', // 校验站点是否存在
    addExistStation: '/api/admin/addStationExists', // 调用已有站
    getStationPopularInfo: '/api/admin/getStationPopularInfo', // 获取推广信息
    saveStationPopular: '/api/admin/saveStationPopular', // 保存推广信息
    goToPreview: '/api/admin/page/info', // 预览
    getCategoryList: '/api/admin/cate/list', // 获取所有的产品分类列表
    addCategory: '/api/admin/cate/add', // 添加产品分类,
    editCategoryName: '/api/admin/cate/edit/name', // 编辑产品名称,
    deleteCategory: '/api/admin/cate/del', // 删除分类,
    sortCategory: '/api/admin/cate/sort', // 分类排序,
    relateProducts: '/api/admin/cate/edit/goods', // 关联商品
    getRelateProducts: '/api/admin/cate/goods', // 根据分类获取商品列表
    deleteRelateProduct: '/api/admin/cate/goodsdel', // 删除分类中的商品
    getArticleCategoryList: '/api/admin/art/list', // 获取所有的文章分类列表
    addArticleCategory: '/api/admin/art/add', // 新增文章分类
    deleteArticleCategory: '/api/admin/art/del', // 删除分类,
    editArticleCategoryName: '/api/admin/art/edit', // 编辑文章分类,
    getExamList: '/api/admin/art/news/examItem', // 获取考试项目
    getColumnList: '/api/admin/art/news/examColumn', // 获取栏目
    bindArticleCategory: '/api/admin/art/edit/bindArt', // 文章分类绑定考试&栏目
    getArticleListByIds: '/api/admin/article/list', // 获取文章列表并校验IDS
    getcrmInfoByWno: '/api/admin/goods/GetcrmInfoByWno', // 通过工号获取电商crm用户信息
    getAreaList: '/api/admin/area/list', // 获取全部地区列表数据
    getAreaListByPId: '/api/admin/area/by', // 获取地区数据--根据父级id
    // 文章分类-外层树
    getArtConfigList: '/api/admin/art/config/list', // 获取文章分类树-列表
    addArtConfig: '/api/admin/art/config/add', // 新增文章分类树
    editArtConfig: '/api/admin/art/config/edit', // 重命名文章分类树
    // 规格设置
    getnavigationSpecifiList: '/api/admin/specifi/getnavigationSpecifiList', // 获取规格列表
    addNavigationSpecifi: '/api/admin/specifi/AddnavigationSpecifi', // 添加规格信息
    editNavigationSpecifi: '/api/admin/specifi/EditnavigationSpecifi', // 修改规格信息
    delNavigationSpecifi: '/api/admin/specifi/DelnavigationSpecifi', // 删除规格信息
    sortNavigationSpecifi: '/api/admin/specifi/SortnavigationSpecifi', // 规格排序
    // 权限设置
    getUserRbac: '/api/admin/rbac/api/check', // 获取用户权限列表
    rbacPermission: '/api/admin/rbac/api/ram', // 获取rbac权限的配置
    // 商品展示设置
    getGoodsDisplayComponentsList: '/api/admin/multi/module/list', // 获取商品展示组件列表
    getGoodsDisplayComponentInfo: '/api/admin/multi/module/info', // 获取商品展示组件详情
    getGoodsDisplayConfig: '/api/admin/goods/querycon/list/config', // 获取商品展示组件配置项
    getGoodsPropertyClass: '/api/admin/goods/querycon/list', // 获取商品属性分类
    saveGoodsDisplayComponentInfo: '/api/admin/multi/module/manage', // 商品展示组件保存
    deleteGoodsDisplayComponent: '/api/admin/multi/module/manage/delete', // 删除商品展示组件
    GetProductCatesSubjects: '/api/admin/goods/GetProductCatesSubjects', // 取分类&科目列表
    // 小程序列表
    getWxAppList: '/api/admin/jumpconfig/applet',
    // 获取客服列表
    getCallCenter: '/api/admin/callcenter/list',
    // 根据商品货品编码获取cid以及母版信息
    getTemplateList: '/api/admin/goods/GetTemplateList',
    // 根据母版ids获取母版具体信息
    batchGetTemplateInfo: '/api/admin/goods/BatchGetTemplateInfo',
    // 根据母版ids获取母版下的组件信息
    getTemplateOutline: '/api/admin/goods/GetTemplateOutline',
    // 复用型筛选项列表
    getSelectList: '/api/admin/multi/select/list',
    // 复用型筛选项删除
    deleteSelectById: '/api/admin/multi/select/del',
    // 复用型筛选项详情
    getSelectInfoById: '/api/admin/multi/select/info',
    // 新增或编辑筛选项
    saveSelect: '/api/admin/multi/select/manage',
    // 新增或编辑筛选内容值
    saveSelectValue: '/api/admin/multi/select/value/manage',
    // 删除筛选内容值
    deleteSelectValue: '/api/admin/multi/select/value/del',
    // 练习册组件列表
    getWorkbookGroupList: '/api/admin/multi/practiceModule/list',
    // 练习册列表
    getWorkbookList: '/api/admin/multi/parctice/list',
    // 添加练习册弹窗筛选项配置考试项目 + 招考地区
    getWorkbookSearchConfig: '/api/admin/multi/parctice/condition',
    // 练习册组件保存
    saveWorkbookComponentInfo: '/api/admin/multi/practiceModule/manage',
    // 获取练习册组件详情
    getWorkbookComponentInfo: '/api/admin/multi/practiceModule/info',
    // 练习册组件调用情况
    getWorkbookGroupCallStatus: '/api/admin/multi/practiceModule/call',
    // 删除练习册组件
    deleteWorkbookComponent: '/api/admin/multi/practiceModule/delete',
    // 题库SDK支持类型
    getTikuSDK: '/api/admin/jumpconfig/tikusdk',
    // 校验题库SDK配置
    checkTikuSDK: '/api/admin/tikusdk/check',
    // 查询某一用户店铺权限
    getShopPermissionListByUser: '/api/adminshop/user/list',
    // 删除店铺权限
    deleteShopPermissionByUser: '/api/adminshop/user/del',
    // 添加店铺权限
    addShopPermissionByUser: '/api/adminshop/user/add',
    // 获取站点页面列表&模板站点页面列表
    getPageListNew: '/api/admin/material/page',
    // 站点列表_页面跳转跨站点
    getStationListJump: '/api/admin/getStationListJump',
    // 将站点存为模板站点
    saveStationAsReuse: '/api/admin/material/apply/save',
    // 获取模板站点列表
    getReuseWebsiteList: '/api/admin/material/list',
    // 新建模板站点
    addReuseWebsite: '/api/admin/material/add',
    // 编辑模板站点
    editReuseWebsite: '/api/admin/material/edit',
    // 删除模板站点
    delReuseWebsite: '/api/admin/material/del',
    // 获取模板站点被引用的站点列表
    getApplyWebsiteListById: '/api/admin/material/apply/uselist',
    // 站点引用模板站点数据
    applyReuseWebsite: '/api/admin/material/apply/use',
    // 站点取消应用模板站点
    cancelReuseWebsite: '/api/admin/material/apply/cancel',
    // 获取应用站点中所有页面信息（包括普通页面、特殊页面以及关联的模板站点所有页面）
    getWebsiteAllPage: '/api/admin/material/pagelist',
    // 获取电商全量会员卡列表
    getVipCardList: '/api/admin/goods/GetGoodsMemberList',
    // 校验试听
    checkVideo: '/api/admin/multi/media/check',
    // --------web---------
    getWebHomePage: '/api/home/page',
    getWebCategoryList: '/api/home/category/list', // 分类
    getSearch: '/api/home/goods/s', // 搜索
    goodsBuyStatus: '/api/v1/goods_buy_status', // 商品购买情况
    // 注册引导
    guideList: '/api/admin/guide/list',
    guideSave: '/api/admin/guide/save',
    guideInfo: '/api/admin/guide/info',
    guideDel: '/api/admin/guide/del',
    getShopListAll: '/api/admin/getShopListAll', // 店铺列表
    getStationListGuide: '/api/admin/guide/getStationList', // 站点列表
    editnavigationSpecifi: '/api/admin/specifi/EditnavigationSpecifi', // 规格列表
    // cms2.0(商品自动调取)
    ssoUserinfo: '/api/admin/multi/sso/userinfo', // 查询sso用户信息
    dsshoplist: '/api/admin/goods/querycon/dsshoplist', // 电商店铺列表
    // cms 数据权限
    relatedPageList: '/api/admin/multi/relatedPage/list', // 电商店铺列表
    permissionCheck: '/api/admin/multi/permission/check', // 组件操作权限校验

    // 商城设置-添加广告
    addBanner: '/api/admin/pc/banner/add',
    // 获取banner广告编辑信息
    getBannerInfo: '/api/admin/pc/banner/info',
    // 删除广告
    deleteBanner: '/api/admin/pc/banner/delete',
    // banner广告列表
    getBannerList: '/api/admin/pc/banner/list',
    // 编辑广告
    editBanner: '/api/admin/pc/banner/update',
    // 删除广告
    delBanner: '/api/admin/pc/banner/del',
    // banner列表获取地区数据
    getBannerAreaList: '/api/admin/pc/erp_areas/uselist',
    // banner列表获取考试项目
    getBannerCategoryList: '/api/admin/pc/category/uselist',
    // 地区列表
    getAreaTableList: '/api/admin/pc/erp_areas/list',
    // 更新地区
    updateAreaList: '/api/admin/pc/erp_areas/sync',
    // 地区名称校验
    checkAreaName: '/api/admin/pc/erp_areas/checkname',
    // 地区详情
    areaDetailInfo: '/api/admin/pc/erp_areas/info',
    // 地区保存
    editAreaDetailinfo: '/api/admin/pc/erp_areas/save',
    // 考试项目列表
    getCategoryTableList: '/api/admin/pc/category/list',
    // 考试项目详情
    categoryDetailInfo: '/api/admin/pc/category/info',
    // 考试项目名称校验
    checkCategoryName: '/api/admin/pc/category/checkname',
    // 考试项目保存
    editCategoryDetailInfo: '/api/admin/pc/category/save',
    // 更新考试项目
    updateCategoryList: '/api/admin/pc/category/sync',
    // 分组管理 获取列表
    getGroupList: '/api/admin/pc/categorygroup/list',
    // 分组管理 名称校验
    checkGroupName: '/api/admin/pc/categorygroup/checkname',
    // 分组管理 新增
    addCategoryGroup: '/api/admin/pc/categorygroup/add',
    // 分组管理 编辑
    editCategoryGroup: '/api/admin/pc/categorygroup/save',
    // 分组管理 子项目信息
    getSubprojectinfo: '/api/admin/pc/categorygroup/categoryinfo',
    // 分组管理 删除考试项目
    delCategorygroup: '/api/admin/pc/categorygroup/del',
    // 分组管理 考试项目状态
    saveCategoryGroupStatus: '/api/admin/pc/categorygroup/savestatus',
    // 分组管理 考试项目信息
    categoryGroupDetailInfo: '/api/admin/pc/categorygroup/info',
    // 商品展示组件 批量添加商品日志列表
    getMultiGoodsAddLog: '/api/admin/multi/module/multigoodsaddlog',
    // 商品展示组件 批量添加商品
    multiGoodsAdd: '/api/admin/multi/module/multigoodsadd',
    // 外链-可用一级域名
    getExternalLink: '/api/admin/page/externalLink',
    // 获取最新的全局导航版本
    getStationLatestNavInfo: '/api/admin/stationLatestNavInfo',
    // 获取试卷组件列表
    getTestPaperGroupList: '/api/admin/multi/paperModule/list',
    // 试卷组件新建/编辑
    saveTestPaperModuleComponentInfo: '/api/admin/multi/paperModule/manage',
    // 获取试卷组件详情
    getTestPaperModuleComponentInfo: '/api/admin/multi/paperModule/info',
    // 删除试卷组件
    deleteTestPaperModuleComponent: '/api/admin/multi/paperModule/delete',
    // 题库获取试卷列表
    getTestPaperModuleList: '/api/admin/multi/paper/list',
    // 校验媒资ID
    checkMediaIdValidate: '/api/admin/media/validate',
  },
};

const Interfaces = ServerConfig.interfaces;

export default ServerConfig;
export { Interfaces };
