'use strict';

// babel polyfill for IE
require("core-js/modules/es.symbol");
require("core-js/modules/es.array.iterator");
require("core-js/modules/es.json.to-string-tag");
require("core-js/modules/es.map");
require("core-js/modules/es.math.to-string-tag");
require("core-js/modules/es.object.assign");
require("core-js/modules/es.object.entries");
require("core-js/modules/es.object.freeze");
require("core-js/modules/es.object.from-entries");
require("core-js/modules/es.object.get-own-property-descriptor");
require("core-js/modules/es.object.get-own-property-descriptors");
require("core-js/modules/es.object.get-own-property-names");
require("core-js/modules/es.object.get-prototype-of");
require("core-js/modules/es.object.is");
require("core-js/modules/es.object.is-extensible");
require("core-js/modules/es.object.is-frozen");
require("core-js/modules/es.object.is-sealed");
require("core-js/modules/es.object.keys");
require("core-js/modules/es.object.prevent-extensions");
require("core-js/modules/es.object.seal");
require("core-js/modules/es.object.set-prototype-of");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.object.values");
require("core-js/modules/es.set");
require("core-js/modules/es.string.iterator");
require('react-app-polyfill/ie9');

// if (typeof Promise === 'undefined') {
//   // Rejection tracking prevents a common issue where React gets into an
//   // inconsistent state due to an error, but it gets swallowed by a Promise,
//   // and the user has no idea what causes React's erratic future behavior.
//   require('promise/lib/rejection-tracking').enable();
//   window.Promise = require('promise/lib/es6-extensions.js');
// }

// fetch() polyfill for making API calls.
// require('whatwg-fetch');
//
// // default animation frame polyfill for IE
// require('requestanimationframe');