// 获取阿里云oss上传配置项
const GETOSSCONFIG = 'GETOSSCONFIG';
const GETPRODUCTCATES = 'GETPRODUCTCATES';
const GETPRODUCTSUBJECTS = 'GETPRODUCTSUBJECTS';
const GETPRODUCTYEARS = 'GETPRODUCTYEARS';

export {
  GETOSSCONFIG,
  GETPRODUCTCATES,
  GETPRODUCTSUBJECTS,
  GETPRODUCTYEARS,
};
