import React from 'react';
import Loadable from 'react-loadable';

const LazyLoading = () => <div />;

const loadPage = (component, defaultLoading = LazyLoading) => {
  return Loadable({
    loader: () => component,
    loading: defaultLoading,
  });
};

export const LoadableShopSandboxPage = loadPage(import('./shop-sandbox/index'));
// 装修
export const LoadableDecorePage = loadPage(import('./decore-page'));
// app装修
export const LoadableAppDecorePage = loadPage(import('./app'));
// 产品分类设置
export const LoadableProductCategorySetting = loadPage(import('./product-category-setting'));
// 产品分类设置-关联商品
export const LoadableCategoryRelateProducts = loadPage(import('./category-relate-products'));

// 文章分类设置
export const LoadableArticleCategorySetting = loadPage(import('./article-category-setting'));
// 文章分类设置-tree
export const LoadableArticleCategorySettingTree = loadPage(import('./article-category-setting-tree'));
// 权限管理
export const LoadableAuthTree = loadPage(import('./auth-tree'));

// 规格设置
export const LoadableSpecsSetting = loadPage(import('./specs-setting'));

// 商品展示设置
export const LoadableGoodsDisplaySetting = loadPage(import('./goods-display-setting'));

// 近期直播推荐设置
export const LoadableTodayPromotionSetting = loadPage(import('./today-promotion-setting'));

// 练习册组设置
export const LoadableWorkbookSetting = loadPage(import('./workbook-setting'));

export const LoadableShopProtect = loadPage(import('./shop-protect'));
export const LoadableWebsiteProtect = loadPage(import('./website-protect'));
export const LoadableHomeWebsiteListPage = loadPage(import('./website-list/index'));

// 店铺权限查询&配置页面
export const LoadableShopPermissionPage = loadPage(import('./shop-permission/index'));

// 模板站点列表页
export const LoadableReuseWebsiteList = loadPage(import('./reuse-website-list/index'));

// 模板站点装修
export const LoadableReuseWebsiteDecorePage = loadPage(import('./reuse-website-page'));
// 注册引导
export const LoadableregisterLeadPage = loadPage(import('./register-lead/index'));

// 商城设置-banner
export const LoadableMallSettingBannerPage = loadPage(import('./mall-setting-banner/index'));
// 商城设置-考试项目
export const LoadableMallSettingExamPage = loadPage(import('./mall-setting-exam/index'));
// 商城设置-地区
export const LoadableMallSettingAreaPage = loadPage(import('./mall-setting-area/index'));
// 试卷组件设置
export const LoadableTestpaperSetting = loadPage(import('./testpaper-setting'));
