// 权限分配记录
const RbacList = [
  // 顺序不要变哈，实际使用的是RbacList[x];
  {
    index: 0,
    rbac: 'omcms_reuse_website_list_show_view',
    desc: 'OMCMS-模板站管理-视图展示',
  },
  {
    index: 1,
    rbac: 'omcms_save_station_as_reuse',
    desc: 'OMCMS-普通站点-页面列表-存为模板站',
  },
  {
    index: 2,
    rbac: 'omcms_mall_setting',
    desc: 'OMCMS-商城设置',
  },
  {
    index: 3,
    rbac: 'omcms_mall_admin',
    desc: 'OMCMS-商城设置-考试项目地区管理权限',
  },
  {
    index: 4,
    rbac: 'omcms_global_nav',
    desc: 'OMCMS-全局导航编辑权限',
  },
];
export default RbacList;
