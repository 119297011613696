// 获取页面列表
const GETPAGELIST = 'GETPAGELIST';
// 获取页面详情
const GETPAGEINFO = 'GETPAGEINFO';

// 获取页面列表
const GETPAGELISTNEW = 'GETPAGELISTNEW';

// 保存currentPage信息
const UPDATECURRENTPAGE = 'UPDATECURRENTPAGE';

export {
  GETPAGELIST,
  GETPAGEINFO,
  UPDATECURRENTPAGE,
  GETPAGELISTNEW,
};
