// import { GETUSERINFO, UPDATEUSERRBAC } from './action-type';
import * as actionType from './action-type';
import FetchUitl from '../../utils/fetch-util';

import { Interfaces } from '../../service/serverConfig';

const getUserInfoSync = (userid, username, rbacGroup, isadmin) => {
  return {
    userid,
    username,
    rbacGroup,
    isadmin,
    type: actionType.GETUSERINFO,
  };
};

const updateUserRbac = (status = -1, data = {}, msg = '') => {
  return {
    type: actionType.UPDATEUSERRBAC,
    status,
    data,
    msg,
  };
};

const getUserInfo = () => {
  return (dispatch) => {
    window.sso.ready().then((user) => {
      const ssoData = {
        sso_id: user.getId(),
        username: user.getUsername(),
      };
      FetchUitl.get(Interfaces.userinfo, {}, (data) => {
        return dispatch(getUserInfoSync(ssoData.sso_id, ssoData.username, { group: data.group_1, group_ids: data.group_ids }, data.is_admin));
      }, () => {
        return dispatch(getUserInfoSync(ssoData.sso_id, ssoData.username, { group: null, group_ids: null }, false));
      });
      // return dispatch(getUserInfoSync(ssoData.sso_id, ssoData.username));
    });
  };
};

const getUserRbac = (params) => {
  return (dispatch) => {
    FetchUitl.get(Interfaces.getUserRbac, params, (data) => {
      return dispatch(updateUserRbac(0, data, ''));
    }, (msg, code) => {
      return dispatch(updateUserRbac(code, null, msg));
    });
  };
};

export {
  getUserInfo,
  getUserRbac,
};
