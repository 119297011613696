import UPDATEGLOBALLOAD from './action-type';

// 列表
const DefaultLoading = false;
const DefaultStore = {
  globalLoading: DefaultLoading,
};

const global = (state = DefaultStore, action = {}) => {
  switch (action.type) {
    case UPDATEGLOBALLOAD:
      return { ...state, globalLoading: action.globalLoading };
    default:
      return state;
  }
};

export default global;
