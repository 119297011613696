import * as actype from './action-type';

// 列表
const DefaultPageList = {
  status: -2,
  data: [],
  msg: '',
};

// 当前页面信息
const DefultCurrentPage = {};

// 详情
const DefaultPageInfo = {
  status: -2,
  data: {},
  msg: '',
};
const DefaultStore = {
  pageList: { ...DefaultPageList },
  pageInfo: { ...DefaultPageInfo },
  currentPage: { ...DefultCurrentPage },
};

const page = (state = DefaultStore, action = {}) => {
  switch (action.type) {
    case actype.GETPAGELIST:
      return { ...state, pageList: { data: action.data, status: action.status, msg: action.msg } };
    case actype.GETPAGELISTNEW:
      return { ...state, pageList: { data: action.data, status: action.status, msg: action.msg } };
    case actype.UPDATECURRENTPAGE:
      return { ...state, currentPage: action.data };
    case actype.GETPAGEINFO:
      return { ...state, pageInfo: { data: action.data, status: action.status, msg: action.msg } };
    default:
      return state;
  }
};

export default page;
