/**
 * Created by mzj on 2018/5/17.
 */

import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import combineReducers from './reducers';

// Create a store for redux.
// Using these two Middleware;
//     ---- routermiddleware FOR react-router-redux
//     ---- thunk FOR async request
const store = process.env.NODE_ENV === 'development' ? createStore(
  combineReducers,
  // packaging for redux-devtools
  composeWithDevTools(applyMiddleware(thunk)),
) : createStore(
  combineReducers,
  applyMiddleware(thunk),
);

export default store;
