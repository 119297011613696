import * as actionType from './action-type';
import getPayLoad from '../../utils/get-payload-from-action';

const defaultData = {
  status: -2,
  data: [],
  msg: '',
};

const defaultAction = {
  status: -2,
  msg: '',
};

const defaultJson = {
  status: -2,
  data: {},
  msg: '',
};

const defaultShopStationList = {
  stations: { ...defaultData },
  addStationAction: { ...defaultAction },
  detail: { ...defaultJson },
  addExistStation: { ...defaultAction },
  stationPopularInfo: { ...defaultJson },
};

function shopStationInfo(states = defaultShopStationList, action) {
  switch (action.type) {
    case actionType.UPDATESHOPSTATIONLIST:
      return { ...states, stations: getPayLoad(action) };
    case actionType.UPDATEADDSHOPSTATION:
      return { ...states, addStationAction: getPayLoad(action) };
    case actionType.UPDATEGETSTATIONDETAIL:
      return { ...states, detail: getPayLoad(action) };
    case actionType.UPDATEADDEXISTSTATION:
      return { ...states, addExistStation: getPayLoad(action) };
    case actionType.UPDATEGETSTATIONPOPULARINFO:
      return { ...states, stationPopularInfo: getPayLoad(action) };
    default:
      return states;
  }
}

export default shopStationInfo;
