import { matchPath } from 'react-router-dom';
import RbacList from '../const';

import {
  LoadableDecorePage,
  LoadableAppDecorePage,
  LoadableShopSandboxPage,
  LoadableShopProtect,
  LoadableWebsiteProtect,
  LoadableHomeWebsiteListPage,
  LoadableProductCategorySetting,
  LoadableCategoryRelateProducts,
  LoadableArticleCategorySetting,
  LoadableArticleCategorySettingTree,
  LoadableSpecsSetting,
  LoadableAuthTree,
  LoadableGoodsDisplaySetting,
  LoadableTodayPromotionSetting,
  LoadableregisterLeadPage,
  LoadableWorkbookSetting,
  LoadableShopPermissionPage,
  LoadableReuseWebsiteList,
  LoadableReuseWebsiteDecorePage,
  LoadableMallSettingBannerPage,
  LoadableMallSettingExamPage,
  LoadableMallSettingAreaPage,
  LoadableTestpaperSetting,
} from './loadable';

const CMS_ROOT = '/web/cms/'; // 后台项目路由

const ROUTESCONFIG = [{
  id: 'root',
  path: CMS_ROOT.slice(0, CMS_ROOT.length - 1),
  pathBase: CMS_ROOT.slice(0, CMS_ROOT.length - 1),
  text: 'OMCMS',
  component: LoadableShopSandboxPage,
}, {
  id: 'shop',
  path: `${CMS_ROOT}:shopId`,
  pathBase: `${CMS_ROOT}:shopId`,
  component: LoadableShopProtect,
  isLayoutRoute: true,
  endNav: true,
  routes: [{
    id: 'home',
    path: `${CMS_ROOT}:shopId/home`,
    pathBase: `${CMS_ROOT}:shopId/home`,
    text: '首页',
    component: LoadableHomeWebsiteListPage,
    endNav: true,
    routes: [{
      id: 'website',
      path: `${CMS_ROOT}:shopId/home/:websiteId`,
      pathBase: `${CMS_ROOT}:shopId/home/:websiteId`,
      component: LoadableWebsiteProtect,
      isLayoutRoute: true,
      endNav: true,
      routes: [{
        id: 'home-decorate',
        path: `${CMS_ROOT}:shopId/home/:websiteId/decorate`,
        pathBase: `${CMS_ROOT}:shopId/home/:websiteId/decorate`,
        component: LoadableDecorePage,
        isLayoutRoute: true,
      }, {
        id: 'app-decorate',
        path: `${CMS_ROOT}:shopId/home/:websiteId/app-decorate`,
        pathBase: `${CMS_ROOT}:shopId/home/:websiteId/app-decorate`,
        component: LoadableAppDecorePage,
        isLayoutRoute: true,
      }],
    }],
  }, {
    id: 'categorySetting',
    path: `${CMS_ROOT}:shopId/categorySetting`,
    pathBase: `${CMS_ROOT}:shopId/categorySetting`,
    text: '产品分类设置',
    component: LoadableProductCategorySetting,
    endNav: true,
    routes: [{
      id: 'categoryRelateProducts',
      path: `${CMS_ROOT}:shopId/categorySetting/relateProducts/:categoryId`,
      pathBase: `${CMS_ROOT}:shopId/categorySetting/relateProducts/:categoryId`,
      text: window.localStorage.getItem('router_name') || '关联商品',
      component: LoadableCategoryRelateProducts,
      // isLayoutRoute: true,
      endNav: true,
    }],
  }, {
    id: 'goodsDisplaySetting',
    path: `${CMS_ROOT}:shopId/goodsDisplaySetting`,
    pathBase: `${CMS_ROOT}:shopId/goodsDisplaySetting`,
    text: '商品展示设置',
    component: LoadableGoodsDisplaySetting,
    endNav: true,
  }, {
    id: 'todayPromotionSetting',
    path: `${CMS_ROOT}:shopId/todayPromotionSetting`,
    pathBase: `${CMS_ROOT}:shopId/todayPromotionSetting`,
    text: '近期直播推荐设置',
    component: LoadableTodayPromotionSetting,
    endNav: true,
  }, {
    id: 'workbookSetting',
    path: `${CMS_ROOT}:shopId/workbookSetting`,
    pathBase: `${CMS_ROOT}:shopId/workbookSetting`,
    text: '练习册组件设置',
    component: LoadableWorkbookSetting,
    endNav: true,
  }, {
    id: 'testPaperSetting',
    path: `${CMS_ROOT}:shopId/testPaperSetting`,
    pathBase: `${CMS_ROOT}:shopId/testPaperSetting`,
    text: '试卷组件设置',
    component: LoadableTestpaperSetting,
    endNav: true,
  }, {
    id: 'articleSetting',
    path: `${CMS_ROOT}:shopId/articleSetting`,
    pathBase: `${CMS_ROOT}:shopId/articleSetting`,
    text: '文章分类管理',
    component: LoadableArticleCategorySetting,
    endNav: true,
    routes: [{
      id: 'articleSettingTree',
      path: `${CMS_ROOT}:shopId/articleSetting/:treeId`,
      pathBase: `${CMS_ROOT}:shopId/articleSetting/:treeId`,
      text: window.localStorage.getItem('router_name') || '分类树',
      component: LoadableArticleCategorySettingTree,
      // isLayoutRoute: true,
      endNav: true,
    }],
  }, {
    id: 'specsSetting',
    path: `${CMS_ROOT}:shopId/specsSetting`,
    pathBase: `${CMS_ROOT}:shopId/specsSetting`,
    text: '规格设置',
    component: LoadableSpecsSetting,
    endNav: true,
  }, {
    id: 'reuseWebsiteSetting',
    path: `${CMS_ROOT}:shopId/reuseWebsiteSetting`,
    pathBase: `${CMS_ROOT}:shopId/reuseWebsiteSetting`,
    text: '模板站点管理',
    component: LoadableReuseWebsiteList,
    endNav: true,
    rbac: RbacList[0].rbac,
    routes: [{
      id: 'reuse-website-decorate',
      path: `${CMS_ROOT}:shopId/reuseWebsiteSetting/:websiteId/decorate`,
      pathBase: `${CMS_ROOT}:shopId/reuseWebsiteSetting/:websiteId/decorate`,
      component: LoadableReuseWebsiteDecorePage,
      isLayoutRoute: true,
    }],
  },
  {
    id: 'registerLead',
    path: `${CMS_ROOT}:shopId/registerLead`,
    pathBase: `${CMS_ROOT}:shopId/registerLead`,
    text: '注册引导',
    component: LoadableregisterLeadPage,
    endNav: true,
  },
  {
    id: 'mallSetting',
    path: `${CMS_ROOT}:shopId/mallSetting`,
    pathBase: `${CMS_ROOT}:shopId/mallSetting`,
    text: '商城设置',
    rbac: RbacList[2].rbac,
    icon: 'appstore',
    endNav: false,
    routes: [{
      id: 'banner',
      text: 'banner广告',
      path: `${CMS_ROOT}:shopId/mallSetting/banner`,
      pathBase: `${CMS_ROOT}:shopId/mallSetting/banner`,
      component: LoadableMallSettingBannerPage,
    }, {
      id: 'exam',
      text: '考试项目',
      path: `${CMS_ROOT}:shopId/mallSetting/exam`,
      pathBase: `${CMS_ROOT}:shopId/mallSetting/exam`,
      component: LoadableMallSettingExamPage,
      rbac: RbacList[3].rbac,
    }, {
      id: 'area',
      text: '地区',
      path: `${CMS_ROOT}:shopId/mallSetting/area`,
      pathBase: `${CMS_ROOT}:shopId/mallSetting/area`,
      component: LoadableMallSettingAreaPage,
      rbac: RbacList[3].rbac,
    }],
  },
  {
    id: 'rbacSetting',
    path: `${CMS_ROOT}:shopId/rbacSetting`,
    pathBase: `${CMS_ROOT}:shopId/rbacSetting`,
    text: '权限管理',
    component: LoadableAuthTree,
    endNav: true,
    rbac: 'admin',
  }],
},
{
  id: 'shopPermission',
  path: '/web/cms-shop-permission',
  pathBase: '/web/cms-shop-permission',
  text: '店铺权限配置',
  component: LoadableShopPermissionPage,
}];

const findPathBaseInDeep = (routeId, configTree) => {
  let result = '';
  const len = configTree.length;
  for (let i = 0; i < len; i += 1) {
    if (result) break;

    if (routeId === configTree[i].id) {
      result = configTree[i].pathBase;
      break;
    }

    if (configTree[i].routes) {
      result = findPathBaseInDeep(routeId, configTree[i].routes);
    }
  }

  return result;
};

/**
 * 根据 router 配置中的 path regexp
 * 通过传递按序传递参数的方式拼接完整路径的方法
 *
 * @param pathReg: router 中的路由正则
 * @param ...params: 需要赋值的路由参数。正序排列数组
 */
function assemblePath(pathReg, ...params) {
  const dirs = pathReg.split('/');
  let pointer = -1; // params 向后遍历的指针
  const resultArr = dirs.map((item) => {
    if (item.indexOf(':') === 0) {
      pointer += 1;
      return params[pointer];
    }
    return item;
  });

  return resultArr.join('/');
}

export const generatePathBase = (routeId, shopId, ...args) => {
  const pathBase = findPathBaseInDeep(routeId, ROUTESCONFIG);
  let str = '';
  if (pathBase) {
    str = assemblePath(pathBase, shopId, ...args);
  }
  return str;
};

// 修改路由名称
export const editRouterName = (routeId, configTree, newName, callback) => {
  const len = configTree.length;
  for (let i = 0; i < len; i += 1) {
    if (routeId === configTree[i].id) {
      configTree[i].text = newName;
      window.localStorage.setItem('router_name', newName);
      callback();
      break;
    }

    if (configTree[i].routes) {
      editRouterName(routeId, configTree[i].routes, newName, callback);
    }
  }
};

export const matchDeepestRoute = (routeConfig, url, result, shopId, ...args) => {
  const len = routeConfig.length;
  for (let i = 0; i < len; i += 1) {
    const item = routeConfig[i];
    const urlArr = url.split('/');
    if (matchPath(url, { path: item.path, exact: item.exact })) {
      const pathArr = item.path.split('/');
      const LinkArr = [];
      for (let index = 0; index < pathArr.length; index += 1) {
        LinkArr.push(urlArr[index]);
      }
      result.push({
        id: item.id,
        text: item.text,
        link: LinkArr.join('/'),
        endNav: item.endNav,
        isLayoutRoute: item.isLayoutRoute,
      });
      if (item.routes) {
        matchDeepestRoute(item.routes, url, result, shopId, ...args);
      }
    }
  }

  return result;
};

export default ROUTESCONFIG;

export {
  CMS_ROOT,
};
