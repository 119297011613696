import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import * as shopAction from '../redux/shop/action';
import ROUTESCONFIG from './route-config';
import { getUserInfo, getUserRbac } from '../redux/user/action';

function CMSIndex() {
  const dispatch = useDispatch();
  const globalLoading = useSelector(store => store.global.globalLoading);
  useEffect(() => {
    dispatch(shopAction.getAllShopsList());
    dispatch(getUserInfo());
    dispatch(getUserRbac({ access: '' }));
    // const listener = (ev) => {
    //   ev.preventDefault();
    //   ev.returnValue = '文章要保存吼，确定离开吗？';
    // };
    // window.addEventListener('beforeunload', listener);
    // return () => {
    //   window.removeEventListener('beforeunload', listener);
    // };
  }, []);

  const render = () => {
    return (
      <Spin spinning={globalLoading}>
        <Router>
          {ROUTESCONFIG.map((route) => {
            return (
              <Route
                key={route.id}
                exact={!route.routes}
                path={route.path}
                render={props => <route.component {...props} currentRoute={route} />}
              />
            );
          })}
        </Router>
      </Spin>
    );
  };

  return render();
}

export default CMSIndex;
