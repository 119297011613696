const UPDATESHOPSTATIONLIST = 'UPDATESHOPSTATIONLIST';
const UPDATEADDSHOPSTATION = 'UPDATEADDSHOPSTATION';
const UPDATEGETSTATIONDETAIL = 'UPDATEGETSTATIONDETAIL';
const UPDATEADDEXISTSTATION = 'UPDATEADDEXISTSTATION';
const UPDATEGETSTATIONPOPULARINFO = 'UPDATEGETSTATIONPOPULARINFO';
const UPDATESAVESTATIONPOPULARINFO = 'UPDATESAVESTATIONPOPULARINFO';

export {
  UPDATESHOPSTATIONLIST,
  UPDATEADDSHOPSTATION,
  UPDATEGETSTATIONDETAIL,
  UPDATEADDEXISTSTATION,
  UPDATEGETSTATIONPOPULARINFO,
  UPDATESAVESTATIONPOPULARINFO,
};
