import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import store from './redux/store';
import AdminApp from './cms/index';
import './adminIndex.scss';

window.sso.event('logged', () => {});

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <AdminApp />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);
