import * as actionTypes from './action-type';
import getPayLoad from '../../utils/get-payload-from-action';

const defaultAllShopsList = {
  status: -2,
  data: null,
  msg: '',
};

const defaultShopPermission = {
  all: { ...defaultAllShopsList },
};

function shopPermissions(states = defaultShopPermission, action) {
  switch (action.type) {
    case actionTypes.UPDATEALLSHOPSLIST:
      return { ...states, all: getPayLoad(action) };
    default:
      return states;
  }
}

export default shopPermissions;
